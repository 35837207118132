/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  'general.copyright': 'Connectop 0.1.0 © כל הזכוית שמורות',

  "unauthorized.title": "גישה נדחתה",
  "unauthorized.detail": "אינך מורשה לצפות בדף זה",

  /* 02.User Login, Logout, Register */
  'user.login-title': 'כניסה',
  'user.register': 'הרשמה',
  'user.forgot-password': 'שכחתי סיסמא',
  'user.email': 'דוא"ל',
  'user.password': 'סיסמא',
  'user.forgot-password-question': 'שכחתי סיסמא?',
  'user.fullname': 'שם מלא',
  'user.login-button': 'כניסה',
  'user.register-button': 'הרשמה',
  'user.reset-password-button': 'שחזור סיסמא',
  'user.buy': 'קניה',
  'user.username': 'שם משתמש',

  /* 03.Menu */
  'menu.home': 'דף בית',
  'menu.app': 'דף בית',
  'menu.dashboards': 'סטטיסטיקות',
  'menu.gogo': 'Gogo',
  'menu.start': 'התחל',
  'menu.second-menu': 'תפריט שני',
  'menu.second': 'שני',
  'menu.ui': 'תצוגה',
  'menu.charts': 'גרפים',
  'menu.chat': 'צ\'אט',
  'menu.chats': 'צ\'אטים',
  'menu.survey': 'סקר',
  'menu.todo': 'טו דו',
  'menu.search': 'חיפוש',
  'menu.docs': 'מסמכים',
  'menu.blank-page': 'דף ריק',

  'menu.security': 'אבטחה',
  'menu.organisations': 'אירגונים',
  'menu.users': 'משתמשים',
  'menu.webhooks': 'Webhooks',
  'menu.routes': 'ניתובים',
  'menu.bots': 'ניהול בוטים',
  'menu.data-sources': 'ניהול מידע',
  'menu.bot-list': 'רשימת בוטים',
  'menu.states-groups': 'קבוצות מצבים',
  'menu.bot-states': 'מצבי הבוט',
  'menu.bot-state': 'ניהול מצב',
  'menu.bot-rules': 'חוקי הבוט (Rules)',
  'menu.bot-statistics': 'סטטיסטיקות',

  'top-nav.organisation-switch': 'בחר ארגון נוכחי',
  'top-nav.bot-switch': 'בחר בוט נוכחי',
  'top-nav.dark-mode': 'מצב כהה',
  'profile.logout': 'התנתק',


  /* 04.Error Page */
  'pages.error-title': 'אופס... נראה שקיימת בעייה',
  'pages.error-code': 'קוד שגיאה',
  'pages.go-back-home': 'חזור לדף הבית',


  'pages.add-new': 'הוסף',
  'pages.delete': 'מחיקה',
  'pages.edit': 'עריכה',
  'pages.submit': 'שמירה',
  'pages.cancel': 'ביטול',
  'pages.display-options': 'אפשרויות תצוגה',
  'pages.delete_confirmation': 'האם הנך מאשר את המחיקה?',
  'pages.priority_confirmation': 'האם הינך מאשר את שינוי הסדר?',
  'pages.loading': 'בטעינה...',
  'pages.no_data_found': 'לא נמצא מידע',

  'security.new-organisation': 'הוסף ארגון',
  'security.edit-organisation': 'עדכון ארגון',
  'security.new-user': 'משתמש חדש',
  'security.edit-user': 'ערוך פרטי משתמש',
  'security.new-webhook': 'הוסף Webhook',
  'security.edit-webhook': 'ערוך Webhook',

  'bots.new-bot': 'הוסף בוט',
  'bots.edit-bot': 'עדכון בוט',
  'bots.new-state': 'הוסף מצב',
  'bots.edit-state': 'עריכת הגדרות מצב',
  'bots.new-message': 'הוסף הודעה',
  'bots.edit-message': 'ערוך הודעה',
  'bots.new-option-rec': 'הוסף אופציה',
  'bots.edit-option-rec': 'ערוך אופציה',
  'bots.new-validation': 'הוסף בדיקת אימות',
  'bots.edit-validation': 'ערון בדיקת אימות',
  'bots.new-bot-rule': 'הוסף חוק (Rule)',
  'bots.edit-bot-rule': 'ערוך חוק (Rule)',
  'bots.new-states-group': 'הוסף קבוצת מצבים',
  'bots.edit-states-group': 'ערוך קבוצת מצבים',

  'forms.name': 'שם',
  'forms.description': 'תיאור',
  'forms.active': 'פעיל',
  'forms.session-timeout': 'אורך סשן (בשניות)',
  'forms.profile-picture-link': 'לינק לתמונת פרופיל',
  'forms.exit_key_words': 'מילות מפתח עבור "יציאה"',
  'forms.skip_key_words': 'מילות מפתח עבור "דלג"',
  'forms.go_back_key_words': 'מילות מפתח עבור "הקודם"',
  'forms.texts': 'טקסטים',
  'forms.messages': 'הודעות',
  'forms.options_recs': 'אופציות',
  'forms.validation_settings': 'בדיקות נתונים',
  'forms.key-words': 'מילות מפתח (ניתן להפריד בפסיק)',
  'forms.select-options-msg': 'הודעת בחירת אופציה',
  'forms.default_skip_announce': 'הודעת ניתן לדלג',
  'forms.exit_text': 'הודעה ביציאה מהבוט',
  'forms.invalid_type_default_msg': ' הודעת סוג קלט אינו תקין',
  'forms.invalid_options_default_msg': 'הודעת אופציה אינה תקינה',
  'forms.invalid_boolean_default_msg': 'הודעת אופציה  בוליאנית אינה תקינה',
  'forms.state_type': 'סוג מצב',
  'forms.form_var_name': 'שם המשתנה (בטופס - אם קיים)',
  'forms.bot_states_group': 'קבוצת מצבים',
  'forms.default_value': 'ערך ברירת מחדל',
  'forms.required': 'חובה (א"א לדלג)',
  'forms.next_state': 'המצב הבא',
  'forms.default_next_state': 'המצב הבא במידה ולא הוגדר באופציה עצמה',
  'forms.true_next_state': 'המצב הבא כאשר התשובה כן',
  'forms.false_next_state': 'המצב הבא כאשר התשובה לא',
  'forms.msg_type': 'סוג הודעה',


  'forms.first-state': 'מצב כניסה',
  'forms.default-state': 'מצב ברירת מחדל',
  'forms.exist-state': 'מצב קיים',
  'forms.new-state': 'מצב חדש',
  'forms.states-group': 'קבוצת מצבים',
  'forms.state': 'מצב',

  'forms.link': 'קישור',
  'forms.document_file_name': 'שם המסמך',
  'forms.text': 'טקסט',
  'forms.coordinates': 'קואורדינטות',
  'forms.key_words': 'מילות מפתח',
  'forms.validation_type': 'סוג בדיקת אימות',
  'forms.more_info': 'מידע נוסף',
  'forms.err_message': 'הודעת השגיאה שתוצג',
  'forms.only_if_invalid_input': 'כאשר הקלט אינו תקין',
  'forms.priority': 'עדיפות',
  'forms.match_type': 'סוג התאמה',
  'forms.action_succeed': 'הפעולה הסתיימה בהצלחה',
  'forms.form_name': 'שם טופס',
  'forms.current_bot': 'בוט נוכחי',
  'forms.current_route': 'ניתוב נוכחי',
  'forms.date_range': 'טווח תאריכים',
  'forms.start_date': 'מתאריך',
  'forms.end_date': 'עד תאריך',
  'forms.send_refresh': 'שלח/רענן',
  'forms.all_the_rest': 'כל השאר',
  'forms.bot_activity': 'פעילות בבוט',
  'forms.users_number': 'מספר משתמשים',
  'forms.messages_number': 'מספר הודעות',
  'forms.states_usage': 'שימוש במצבים',
  'forms.states_left': 'נטישה במצבים',
  'forms.usage': 'שימוש',
  'forms.percentage': 'אחוז',
  'forms.email': 'כתובת מייל',
  'forms.phone_number': 'מספר טלפון',
  'forms.permission_level': 'רמת הרשאה(0-999)',
  'forms.password': 'סיסמא',
  'forms.url': 'URL',
  'forms.api_method_type': 'API שיטת',
  'forms.api_headers': 'API Headers',
  'forms.api_options': 'API אופציות',



  'validations.required': 'שדה חובה',
  'validations.invalid-link': 'קישור אינו תקין',
  'validations.invalid-value': 'ערך לא תקין',
  'validations.invalid-email': 'כתובת מייל אינה תקינה',
  'validations.invalid-phone-number': 'מספר טלפון אינו תקין',
  'validations.number': 'נא הזן מספר תקין',
  'validations.min': 'הערך נמוך מהמינימום',
  'validations.max': 'הערך גבוה מהמקסימום',



  'state_types.info': 'אינפורמציה',
  'state_types.options': 'בחירת אופציה',
  'state_types.boolean': 'בחירת כן או לא',
  'state_types.input': 'בקשת תשובה מהמשתמש',
  'state_types.exec_func': 'ביצוע פעולה',

  'msg_type.text': 'טקסט',
  'msg_type.image': 'תמונה',
  'msg_type.audio': 'קובץ קול',
  'msg_type.location': 'מיקום',
  'msg_type.document': 'מסמך',
  'msg_type.video': 'ווידאו',
  'msg_type.sticker': 'מדבקה',
  'msg_type.contacts': 'איש קשר',
  'msg_type.template': 'תבנית',

  'validation_types.email': 'כתובת מייל',
  'validation_types.number': 'מספר',
  'validation_types.regex': 'ביטוי רגולרי',
  'validation_types.phone_number': 'מספר טלפון בינלאומי',
  'validation_types.phone_number_il': 'מספר טלפון ישראלי',

  'match_types.equals': 'שיוויון',
  'match_types.regex': 'ביטוי רגולרי',
  'match_types.key_words': 'מילות מפתח',

  'chat.chats': 'צאטים',
  'chat.to-agent': 'תור למענה אנושי',
  'chat.error_chat_connect': 'בעייה בהתחברות לצאט אונליין',
  'chats.wait_for_agent': 'ממתינ/ה לנציג',
  'chats.by_agent': 'ע"י הנציג'



};
