/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',

  "unauthorized.title": "Unauthorized Access Attempt",
  "unauthorized.detail": "You are not authorized to view the page you are trying to access.",

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Login',
  'user.register': 'Register',
  'user.forgot-password': 'Forgot Password',
  'user.email': 'E-mail',
  'user.password': 'Password',
  'user.forgot-password-question': 'Forget password?',
  'user.fullname': 'Full Name',
  'user.login-button': 'LOGIN',
  'user.register-button': 'REGISTER',
  'user.reset-password-button': 'RESET',
  'user.buy': 'BUY',
  'user.username': 'Username',

  /* 03.Menu */
  'menu.home': 'Home',
  'menu.app': 'Home',
  'menu.dashboards': 'Dashboards',
  'menu.gogo': 'Gogo',
  'menu.start': 'Start',
  'menu.second-menu': 'Second Menu',
  'menu.second': 'Second',
  'menu.ui': 'UI',
  'menu.charts': 'Charts',
  'menu.chat': 'Chat',
  'menu.chats': 'Chats',
  'menu.survey': 'Survey',
  'menu.todo': 'Todo',
  'menu.search': 'Search',
  'menu.docs': 'Docs',
  'menu.blank-page': 'Blank Page',

  'menu.security': 'Security',
  'menu.organisations': 'Organisations',
  'menu.users': 'Users',
  'menu.webhooks': 'Webhooks',
  'menu.routes': 'Routes',
  'menu.bots': 'Bots Management',
  'menu.data-sources': 'Data sources',
  'menu.bot-list': 'Bots List',
  'menu.states-groups': 'States Groups',
  'menu.bot-states': 'Bot States',
  'menu.bot-state': 'State Management',
  'menu.bot-rules': 'Bot Rules',
  'menu.bot-statistics': 'Statistics',

  'top-nav.organisation-switch': 'Chose Current Organisation',
  'top-nav.bot-switch': 'Chose Current bot',
  'top-nav.dark-mode': 'Dark mode',
  'profile.logout': 'Logout',


  /* 04.Error Page */
  'pages.error-title': 'Ooops... looks like an error occurred!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',

  'pages.add-new': 'Add New',
  'pages.delete': 'Delete',
  'pages.edit': 'Edit',
  'pages.submit': 'Save',
  'pages.cancel': 'Cancel',
  'pages.display-options': 'Display Options',
  'pages.delete_confirmation': 'Are You sure you want to delete?',
  'pages.priority_confirmation': 'Are You sure you want to change priority?',
  'pages.loading': 'Loading...',
  'pages.no_data_found': 'No Data Found',

  'security.new-organisation': 'New Organisation',
  'security.edit-organisation': 'Edit Organisation',
  'security.new-user': 'New User',
  'security.edit-user': 'Edit User',
  'security.new-webhook': 'New Webhook',
  'security.edit-webhook': 'Edit Webhook',


  'bots.new-bot': 'New Bot',
  'bots.edit-bot': 'Edit Bot',
  'bots.new-state': 'New State',
  'bots.edit-state': 'Edit State Setting',
  'bots.new-message': 'New Message',
  'bots.edit-message': 'Edit Message',
  'bots.new-option-rec': 'New Option Record',
  'bots.edit-option-rec': 'Edit Option Record',
  'bots.new-validation': 'New Validation',
  'bots.edit-validation': 'Edit Validation',
  'bots.new-bot-rule': 'New Rule',
  'bots.edit-bot-rule': 'Edit Rule',
  'bots.new-states-group': 'New States Group',
  'bots.edit-states-group': 'Edit States Group',



  'forms.name': 'Name',
  'forms.description': 'Description',
  'forms.active': 'Active',
  'forms.session-timeout': 'Session Timeout (seconds)',
  'forms.profile-picture-link': 'Profile Picture Link',
  'forms.exit_key_words': 'Exit key Words',
  'forms.go_back_key_words': 'GoBack key Words',
  'forms.skip_key_words': 'Skip key Words',
  'forms.texts': 'texts',
  'forms.messages': 'messages',
  'forms.options_recs': 'Options',
  'forms.validation_settings': 'Validations',
  'forms.key-words': 'Key Words (can split with ,)',
  'forms.select-options-msg': 'Select an Option Message',
  'forms.default_skip_announce': 'Skip Allowed  Message',
  'forms.exit_text': 'In Exit Message',
  'forms.invalid_type_default_msg': 'Invalid Input Type Message',
  'forms.invalid_options_default_msg': 'Invalid Option Message',
  'forms.invalid_boolean_default_msg': 'Invalid Boolean Option Message',
  'forms.state_type': 'State Type',
  'forms.form_var_name': 'Var Name (in the Form)',
  'forms.bot_states_group': 'States Group',
  'forms.default_value': 'Default Value',
  'forms.required': 'Required (no skipping)',
  'forms.next_state': 'The Next State',
  'forms.default_next_state': 'Default Next State',
  'forms.true_next_state': 'Next State in case of true',
  'forms.false_next_state': 'Next State in case of false',
  'forms.msg_type': 'Massage Type',


  'forms.first-state': 'Enter State',
  'forms.default-state': 'Default State',
  'forms.exist-state': 'Exist State',
  'forms.new-state': 'New State',
  'forms.states-group': 'States Groups',
  'forms.state': 'State',

  'forms.link': 'link',
  'forms.document_file_name': 'Document Name',
  'forms.text': 'Text',
  'forms.coordinates': 'Coordinates',
  'forms.key_words': 'Key Words',
  'forms.validation_type': 'Validation Type',
  'forms.more_info': 'More Info',
  'forms.err_message': 'Error Message',
  'form.only_if_invalid_input': 'When invalid input',
  'forms.priority': 'Priority',
  'forms.match_type': 'Match Type',
  'forms.action_succeed': 'Action Succeed',
  'forms.form_name': 'Form Name',
  'forms.current_bot': 'Current Bot',
  'forms.current_route': 'Current Route',
  'forms.date_range': 'Date Range',
  'forms.start_date': 'Start Date',
  'forms.end_date': 'End Date',
  'forms.send_refresh': 'Send/Refresh',
  'forms.all_the_rest': 'All The Rest',
  'forms.bot_activity': 'Bot Activity',
  'forms.users_number': 'Users Number',
  'forms.messages_number': 'Messages Number',
  'forms.states_usage': 'States Usage',
  'forms.states_left': 'Left in States',
  'forms.usage': 'Usage',
  'forms.percentage': 'Percentage',
  'forms.email': 'Email',
  'forms.phone_number': 'Phone Number',
  'forms.permission_level': 'Permission Level(0-999)',
  'forms.password': 'Password',
  'forms.url': 'URL',
  'forms.api_method_type': 'API Method',
  'forms.api_headers': 'API Headers',
  'forms.api_options': 'API Options',




  'validations.required': 'Required',
  'validations.invalid-link': 'Invalid Link',
  'validations.invalid-value': 'Invalid Value',
  'validations.invalid-email': 'Invalid Email',
  'validations.invalid-phone-number': 'Invalid Phone Number',
  'validations.number': 'Please Put Valid Number',
  'validations.min': 'the value is less than minimum',
  'validations.max': 'the value is more than maximum',





  'state_types.info': 'Info',
  'state_types.options': 'Options',
  'state_types.boolean': 'Boolean',
  'state_types.input': 'Input',
  'state_types.exec_func': 'Execute Action',

  'msg_type.text': 'Text',
  'msg_type.image': 'Image',
  'msg_type.audio': 'Audio',
  'msg_type.location': 'Location',
  'msg_type.document': 'Document',
  'msg_type.video': 'Video',
  'msg_type.sticker': 'Sticker',
  'msg_type.contacts': 'Contact',
  'msg_type.template': 'Template',

  'validation_types.email': 'Email',
  'validation_types.number': 'Number',
  'validation_types.regex': 'Regex',
  'validation_types.phone_number': 'International Phone Number',
  'validation_types.phone_number_il': 'Israeli Phone Number',

  'match_types.equals': 'Equals',
  'match_types.regex': 'Regex',
  'match_types.key_words': 'Key Words',

  'chat.chats': 'Chats',
  'chat.to-agent': 'Queue  for Agent',
  'chat.error_chat_connect': 'Problem Connecting Online Chat',
  'chats.wait_for_agent': 'Waiting For Agent',
  'chats.by_agent': 'By Agent'




};
