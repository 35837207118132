import { getCurrentContext, getCurrentLanguage, getLocalMessage, setEmptyStrToNull, showError } from './Utils';
import NotificationManager from '../components/common/react-notifications/NotificationManager';
import { createConsumer } from "@rails/actioncable"


// const connectopBaseUrl = `${'http://127.0.0.1:3000' || 'https://boti-api1.callbuy.me'}/api/v1/connectop_api`
const connectopBaseUrl = `${(process.env.REACT_APP_CONNECTOP_API_URL || 'https://connectop-api-01.callbuy.me')}/api/v1/connectop_api`
const connectopWsUrl = `${(process.env.REACT_APP_CONNECTOP_API_URL || 'https://connectop-api-01.callbuy.me')}/websocket`
const brandKey = `${process.env.REACT_APP_BRAND_KEY || '268b4bb3-e06d-4677-9151-64fb20ae6a81'}`

const getConnectopHeadersFromParams = (email, password) =>{
  const today = new Date().toISOString();
  const md5 = require('md5');
  const hashToken =  md5(`${email}${password}${today}`)
  return {
    'Content-Type': 'application/json',
    'Email': email,
    'Timestamp': today,
    'Hash-Token': hashToken,
    'Brand-Key': brandKey
  }
}

const getConnectopHeaders = ()=>{
  const currentContext = getCurrentContext();
  return getConnectopHeadersFromParams(currentContext.context.email, currentContext.context.password);
}

const getConnectopConfig = ()=>{
  const currentContext = getCurrentContext();
  return {current_bot_id: currentContext.context.current_bot_id,
    current_organisation_id: currentContext.context.current_organisation_id,
    lang: getCurrentLanguage()};
}

export const connectopDispatcher = (action_type, options) =>{
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ title: 'React POST Request Example' })
  };
  fetch('https://jsonplaceholder.typicode.com/invalid-url', requestOptions)
    .then(async response => {
      const data = await response.json();

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      this.setState({ postId: data.id })
    })
    .catch(error => {
      this.setState({ errorMessage: error.toString() });
      console.error('There was an error!', error);
    });
}

export const connectopLoginDispatcher = async (email, password) =>{
  console.log("Exec Login Dispatcher");
  const requestOptions = {
    method: 'POST',
    headers: getConnectopHeadersFromParams(email, password),
    body: JSON.stringify({ })
  };

  const response =  await fetch(`${connectopBaseUrl}/login`, requestOptions)
  const data = await response.json();
  console.log('All GOOD!', data);
  return data;
}

export const connectopListDispatcher = async (list_name, params) =>{
  console.log("Exec List Dispatcher");
  const body = {
    config: getConnectopConfig(),
    data: {list_name: list_name, params: params}
  };
  const requestOptions = {
    method: 'POST',
    headers: getConnectopHeaders(),
    body: JSON.stringify(body)
  };
  const response =  await fetch(`${connectopBaseUrl}/list`, requestOptions)
  const data = await response.json();
  console.log('List Results!', data);
  return data;
}

export const connectopGetItemDispatcher = async (item_name, params = {}) =>{
  console.log("Exec Get Item Dispatcher");
  params = params || {};
  params.item_name = item_name
  const body = {
    config: getConnectopConfig(),
    data: params
  };
  const requestOptions = {
    method: 'POST',
    headers: getConnectopHeaders(),
    body: JSON.stringify(body)
  };
  const response =  await fetch(`${connectopBaseUrl}/item_get`, requestOptions)
  const data = await response.json();
  console.log('Item Results!', data);
  return data;
}

export const connectopSaveItemDispatcher = async (item_name, params = {}) =>{
  console.log("Exec Save Item Dispatcher");
  params = params || {};
  params.item_name = item_name
  const body = {
    config: getConnectopConfig(),
    data: setEmptyStrToNull(params)
  };
  const requestOptions = {
    method: 'POST',
    headers: getConnectopHeaders(),
    body: JSON.stringify(body)
  };
  const response =  await fetch(`${connectopBaseUrl}/item_save`, requestOptions)
  const data = await response.json();
  console.log('Item Save result!', data);
  return data;
}

export const connectopMultipleActionDispatcher = async (params, class_name, func_name ) =>{
  console.log("Exec Save Item Dispatcher");
  const payloadData =  {func_name: func_name,item_class_name: class_name, params:  params};
  const body = {
    config: getConnectopConfig(),
    data: payloadData
  };
  const requestOptions = {
    method: 'POST',
    headers: getConnectopHeaders(),
    body: JSON.stringify(body)
  };
  const response =  await fetch(`${connectopBaseUrl}/multiple_actions`, requestOptions)
  const data = await response.json();
  console.log('Item Save result!', data);
  if (data.find((k)=> k.error_code)){
    const err_msg = data.map((i)=> {
      if (i.success) {
        return getLocalMessage('forms.action_succeed');
      } else {
        return getCurrentLanguage() === 'he'? i.show_to_user_he : i.show_to_user_en;
      }
    }).join("\n");
    showError(err_msg, data)
  }else{
    NotificationManager.info( getLocalMessage('forms.action_succeed'));
  }
  return data;
}

export const connectopStreamConnect = (route_id, receiveFunc, errFunc  ) =>{
  const headers = getConnectopHeaders()
  headers.route_id = route_id
  let str = [];
  for (let p in headers)
    if (headers.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(headers[p]));
    }
  const consumer =  createConsumer(`${connectopWsUrl}?${str.join("&")}`)
  console.log("Consumer:", consumer);
  const chatChannel = consumer.subscriptions.create({ channel: "ChatChannel", route_id: route_id }, {
    received(data) {
      console.log("socket data:", data);
      window.dispatchEvent(new CustomEvent("new_chat_msg", { bubbles: true, detail: data }))
    },
    disconnected(){
      console.log("socket disconnected");
      // errFunc();
    }
  })

  // chatChannel.send({ sent_by: "Paul", body: "This is a cool chat app." })
  // consumer.subscriptions.create({ channel: "CommentsChannel", room: "1st Room" })
  // consumer.subscriptions.create({ channel: "CommentsChannel", room: "1st Room" }, {
  //   received(data) {
  //     console.log("socket data:", data);
  //   }
  // })
  return consumer
}

